import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { locationGroupsQuery } from '../../../queries';
import Tabs from '../../Tabs';
import TabPane from '../../Tabs/TabPane';
import Loader from '../../Loader';
import TabContent from '../SearchLocationTabComponent';
import './styles.scss';

const LocationTabs = ({
  onChangeCheckboxHandler,
  chosenLocationIds,
  currentCityId
}) => {
  const [currentTabId, setCurrentTabId] = useState(0);
  const [townshipsCount, setTownshipsCount] = useState(0);

  const locationGroupsQueryVariables = {
    ids: [1, 2, 3, 4],
    cityId: currentCityId
  };

  const { data, loading, error } = useQuery(
    locationGroupsQuery(locationGroupsQueryVariables),
    {
      variables: locationGroupsQueryVariables
    }
  );

  if (error) return null;

  const isRegionGroup = id => id === '2';

  const locationsByGroups = () => {
    const { locationGroups: lg } = data || [];
    const locationGroups = lg?.filter(g => g.locations.length > 0);
    let { locations: townships } =
      locationGroups?.find(gr => gr.id === '3') || {};
    const locationGroupsForTabs = locationGroups?.filter(gr => gr.id !== '3');
    if (locationGroupsForTabs?.length > 1) {
      return (
        <div className="location-tabs">
          <Tabs
            selected={currentTabId}
            switchTab={setCurrentTabId}
            scopeClass="locations"
          >
            {locationGroupsForTabs.map(group => {
              const { id, name, locations } = group;
              townships = isRegionGroup(id) ? townships : null;
              const chosenLocationsCount = () => {
                let locationsCount = 0;
                locations.forEach(location => {
                  if (chosenLocationIds.includes(location.id))
                    locationsCount += 1;
                });
                if (isRegionGroup(id)) locationsCount += townshipsCount;

                return locationsCount > 0 ? locationsCount : null;
              };

              return (
                <TabPane
                  key={id}
                  label={name}
                  name={id}
                  additionalClasses={id}
                  itemsCount={chosenLocationsCount()}
                >
                  <TabContent
                    {...{
                      locations,
                      chosenLocationIds,
                      onChangeCheckboxHandler,
                      setTownshipsCount,
                      townships
                    }}
                    locationGroupId={id}
                    isFullName={isRegionGroup(id)}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      );
    }

    if (locationGroupsForTabs?.length === 1) {
      const { id, locations } = locationGroupsForTabs[0];
      townships = isRegionGroup(id) ? townships : null;
      return (
        <TabContent
          {...{
            locations,
            chosenLocationIds,
            onChangeCheckboxHandler,
            setTownshipsCount,
            townships
          }}
          locationGroupId={id}
          isFullName={isRegionGroup(id)}
        />
      );
    }
  };

  return <Loader loading={loading}>{locationsByGroups()}</Loader>;
};

LocationTabs.propTypes = {
  chosenLocationIds: PropTypes.array,
  onChangeCheckboxHandler: PropTypes.func,
  currentCityId: PropTypes.string
};

export default LocationTabs;
