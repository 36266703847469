import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import cn from 'classnames';
import useIphoneDetection from '../../../hooks/useIphoneDetection';
import { locationsQuery } from '../../../queries';
import {
  showPopupHeader,
  setResetPopupHandler,
  setLocationsResetButtonIsDisabled,
  setChosenLocationNames,
  setCurrentCityIsDeferred,
  setCurrentCity,
  setError
} from '../../../actions';
import PopularLocations from '../SearchPopularLocations';
import SearchEmpty from '../SearchEmpty';
import CheckboxButton from '../../CheckboxButton';
import LocationTabs from '../SearchLocationTabs';
import Button from '../../Button';

const mapStateToProps = state => {
  return {
    currentCity: state.searchForm.currentCity
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showPopupHeaderHandler: () => dispatch(showPopupHeader()),
    resetButtonIsDisabled: state =>
      dispatch(setLocationsResetButtonIsDisabled(state)),
    setResetLocationsHandler: handler =>
      dispatch(setResetPopupHandler(handler)),
    chosenLocationNames: names => dispatch(setChosenLocationNames(names)),
    currentCityIsDeferredHandler: state =>
      dispatch(setCurrentCityIsDeferred(state)),
    setCurrentCityHandler: city => dispatch(setCurrentCity(city)),
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const locationsFilter = (locations = [], searchString) => {
  return locations.filter(
    location =>
      !location.name
        .toString()
        .toLowerCase()
        .indexOf(searchString.toLowerCase())
  );
};

const SearchLocations = ({
  closePopupHandler,
  showPopupHeaderHandler,
  setResetLocationsHandler,
  resetButtonIsDisabled,
  chosenLocationNames,
  setCurrentCityHandler,
  cancelHandler,
  isFocused,
  searchString,
  currentCity,
  isInputOnFocus,
  setErrorUI
}) => {
  const { t } = useTranslation();
  const { change, getState } = useForm();
  const isIphone = useIphoneDetection();
  const cityId = getState().values.city_id || '';
  const currentCityId = currentCity || cityId;
  const locationsQueryVariables = {
    cityId: currentCityId,
    scope: 'ALL',
    limit: 1000
  };
  const { data, loading, error } = useQuery(
    locationsQuery(locationsQueryVariables),
    {
      variables: locationsQueryVariables
    }
  );
  const locations = data?.locations || [];
  const currentLocationIds = getState().values.location_ids || [];
  const [chosenLocationIds, setChosenLocationIds] = useState(
    currentLocationIds
  );
  const onChangeCheckboxHandler = e => {
    const currentId = e.target.value;
    const updatedLocationIds = () => {
      return chosenLocationIds.includes(currentId)
        ? chosenLocationIds.filter(el => el !== currentId)
        : [...chosenLocationIds, currentId];
    };
    setChosenLocationIds(updatedLocationIds);
  };

  const closePopup = () => {
    showPopupHeaderHandler();
    closePopupHandler();
    cancelHandler();
    setCurrentCityHandler('');
  };

  const onClickApplyBtnHandler = () => {
    new Promise(resolve => {
      resolve(
        chosenLocationNames(
          locations
            .filter(location => chosenLocationIds.includes(location.id))
            .map(location => location.name.trim())
        )
      );
    }).then(() => {
      change('location_ids', chosenLocationIds);
      if (currentCity) change('city_id', currentCity);
      closePopup();
    });
  };

  const locationsList = locationsFilter(
    locations,
    searchString
  ).map(location => (
    <CheckboxButton
      key={location.id}
      data={location}
      onChangeHandler={onChangeCheckboxHandler}
      currentValues={chosenLocationIds}
      name="search-locations"
      withGroupType={true}
      cypress="location"
    />
  ));

  const emptySearch = !locationsList.length && searchString && (
    <SearchEmpty
      onClickHandler={cancelHandler}
      linkText={t('search.locations_search.back')}
    />
  );
  const popularLocations = !searchString && isFocused && (
    <PopularLocations
      onChangeHandler={onChangeCheckboxHandler}
      currentValues={chosenLocationIds}
      currentCityId={currentCityId}
    />
  );

  const locationTabs = !isFocused && (
    <LocationTabs
      onChangeCheckboxHandler={onChangeCheckboxHandler}
      chosenLocationIds={chosenLocationIds}
      currentCityId={currentCityId}
    />
  );

  useEffect(() => {
    const locationsPopup = document.getElementsByClassName(
      'search-popup--locations'
    );
    const container = locationsPopup[0].querySelector(
      '.search-popup__container'
    );
    const touchHandler = () => {
      const input = locationsPopup[0].querySelector('input[type="search"]');

      input.blur();
    };

    if (isIphone) {
      container.addEventListener('touchstart', touchHandler);
    }

    return () => {
      if (isIphone) {
        container && container.removeEventListener('touchstart', touchHandler);
      }
    };
  }, [isIphone]);

  useEffect(() => {
    setResetLocationsHandler(() => setChosenLocationIds([]));

    return () => {
      setResetLocationsHandler(null);
      resetButtonIsDisabled(false);
    };
  }, []);

  useEffect(() => {
    resetButtonIsDisabled(chosenLocationIds.length === 0);
  }, [chosenLocationIds]);

  useEffect(() => {
    if (!loading && locations.length === 0) {
      if (currentCity) change('city_id', currentCity);
      closePopup();
    }
  }, [data]);

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  return (
    <div
      className={cn([
        'search-popup__container',
        {
          'search-popup__container--locations':
            !(isInputOnFocus || !locationsList.length) && searchString
        }
      ])}
    >
      {isFocused && searchString && locationsList}
      {locationTabs}
      {emptySearch}
      {popularLocations}
      <Button
        additionalClass={cn([
          'search-popup__apply-btn',
          {
            'search-popup__apply-btn__hidden':
              isInputOnFocus || (!locationsList.length && searchString)
          }
        ])}
        text={t('search.buttons.add_to_search')}
        onClickHandler={onClickApplyBtnHandler}
        cypress="locations-apply-btn"
      />
    </div>
  );
};

SearchLocations.propTypes = {
  closePopupHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  showPopupHeaderHandler: PropTypes.func,
  resetButtonIsDisabled: PropTypes.func,
  setResetLocationsHandler: PropTypes.func,
  chosenLocationNames: PropTypes.func,
  setCurrentCityHandler: PropTypes.func,
  isFocused: PropTypes.bool,
  searchString: PropTypes.string,
  currentCity: PropTypes.string,
  isInputOnFocus: PropTypes.bool,
  setErrorUI: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocations);
