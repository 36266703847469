import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SearchInput = ({
  onFocusHandler,
  onChangeHandler,
  onResetHandler,
  isShowReset,
  value,
  refEl,
  placeholder,
  onKeyDown,
  inputMode,
  onBlurHandler,
  id
}) => {
  return (
    <div className="search-input-container">
      <input
        type="search"
        name="search"
        className="search-input"
        id={id}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onKeyDown={onKeyDown}
        value={value}
        autoComplete="off"
        ref={refEl}
        inputMode={inputMode}
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
        placeholder={placeholder}
        tabIndex={-1}
      />
      {isShowReset && (
        <div
          className="search-input__reset-btn"
          onClick={onResetHandler}
          onKeyPress={onResetHandler}
          role="button"
          tabIndex={-1}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onFocusHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onResetHandler: PropTypes.func,
  isShowReset: PropTypes.bool,
  value: PropTypes.string,
  refEl: PropTypes.object,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  inputMode: PropTypes.string,
  onBlurHandler: PropTypes.func,
  id: PropTypes.string
};

export default SearchInput;
