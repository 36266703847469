import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { locationsQuery } from '../../../queries';
import Loader from '../../Loader';
import CheckboxButton from '../../CheckboxButton';

import './styles.scss';

const PopularLocations = ({
  onChangeHandler,
  currentValues,
  currentCityId
}) => {
  const { t } = useTranslation();

  const locationsQueryVariables = {
    cityId: currentCityId,
    scope: 'POPULAR',
    limit: 5
  };

  const { data, loading, error } = useQuery(
    locationsQuery(locationsQueryVariables),
    {
      variables: locationsQueryVariables
    }
  );
  const locations = data?.locations || [];
  const locationsList = locations.map(location => (
    <CheckboxButton
      key={location.id}
      data={location}
      onChangeHandler={onChangeHandler}
      currentValues={currentValues}
      name="search-locations"
      withGroupType={true}
      cypress="location"
    />
  ));

  if (error) {
    return null;
  }
  return (
    <>
      {locationsList.length > 0 && (
        <div className="popular-locations">
          <div className="popular-locations__title">
            {t('search.locations_search.popular_locations')}
          </div>
          <Loader loading={loading}>{locationsList}</Loader>
        </div>
      )}
    </>
  );
};

PopularLocations.propTypes = {
  onChangeHandler: PropTypes.func,
  currentValues: PropTypes.array,
  currentCityId: PropTypes.string
};

export default PopularLocations;
