import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import useIphoneDetection from '../../../hooks/useIphoneDetection';
import { citiesListQuery } from '../../../queries';
import Loader from '../../Loader';
import PopularCities from '../SearchPopularCities';
import SearchEmpty from '../SearchEmpty';
import RadioButton from '../../RadioButton';
import {
  setCitiesList,
  showPopupHeader,
  setChosenLocationNames,
  setCurrentCity,
  setError
} from '../../../actions';

const mapStateToProps = state => {
  return {
    currentCityIsDeferred: state.searchForm.currentCityIsDeferred,
    currentCity: state.searchForm.currentCity
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showPopupHeaderHandler: () => dispatch(showPopupHeader()),
    setCitiesListHandler: cities => dispatch(setCitiesList(cities)),
    setCurrentCityHandler: city => dispatch(setCurrentCity(city)),
    chosenLocationNames: names => dispatch(setChosenLocationNames(names)),
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const citiesFilter = (cities = [], searchString) => {
  return cities.filter(
    city =>
      !city.name.toString().toLowerCase().indexOf(searchString.toLowerCase())
  );
};

const SearchCity = ({
  closePopupHandler,
  showPopupHeaderHandler,
  setCitiesListHandler,
  cancelHandler,
  isFocused,
  searchString,
  currentCityIsDeferred,
  setCurrentCityHandler,
  currentCity,
  chosenLocationNames,
  setErrorUI
}) => {
  const { t } = useTranslation();
  const { change, getState } = useForm();
  const { data, loading, error } = useQuery(citiesListQuery);
  const isIphone = useIphoneDetection();

  const items = [
    {
      id: '0',
      name: t('search.city_search.any'),
      slug: null,
      __typename: 'City'
    }
  ].concat((data && data.cities) || []);

  useEffect(() => {
    setCitiesListHandler(items);
  }, [data]);

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  useEffect(() => {
    const citiesPopup = document.getElementsByClassName('search-popup--cities');
    const container = citiesPopup[0].querySelector('.search-popup__container');
    const touchHandler = () => {
      const input = citiesPopup[0].querySelector('input[type="search"]');

      input.blur();
    };

    if (isIphone) {
      container.addEventListener('touchstart', touchHandler);
    }

    return () => {
      if (isIphone) {
        container && container.removeEventListener('touchstart', touchHandler);
      }
    };
  }, [isIphone]);

  const onChangeRadioHandler = e => {
    const city = e.target.value;
    if (currentCityIsDeferred) {
      setCurrentCityHandler(city);
    } else {
      change('city_id', city);
      change('location_ids', undefined);
      chosenLocationNames([]);
    }
    showPopupHeaderHandler();
    closePopupHandler();
    cancelHandler();
  };
  const citiesList =
    (!isFocused || searchString) &&
    citiesFilter(items, searchString).map(city => (
      <RadioButton
        key={city.id}
        data={city}
        onChangeHandler={onChangeRadioHandler}
        currentValue={currentCity || getState().values.city_id}
        name="search-city"
        cypress="city"
      />
    ));
  const emptySearch = !citiesList.length && searchString && (
    <SearchEmpty
      onClickHandler={cancelHandler}
      linkText={t('search.city_search.back')}
    />
  );
  const popularCities = !searchString && isFocused && (
    <PopularCities
      onChangeHandler={onChangeRadioHandler}
      currentCity={currentCity || getState().values.city_id}
      setErrorUI={setErrorUI}
    />
  );

  if (error) {
    return null;
  }

  return (
    <div className="search-popup__container">
      <Loader loading={loading}>{citiesList}</Loader>
      {emptySearch}
      {popularCities}
    </div>
  );
};

SearchCity.propTypes = {
  closePopupHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  showPopupHeaderHandler: PropTypes.func,
  isFocused: PropTypes.bool,
  searchString: PropTypes.string,
  setCitiesListHandler: PropTypes.func,
  currentCityIsDeferred: PropTypes.bool,
  setCurrentCityHandler: PropTypes.func,
  currentCity: PropTypes.string,
  chosenLocationNames: PropTypes.func,
  setErrorUI: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCity);
